<template>
  <div class="wrapper_main">
    <div class="wrapper_content">
      <div class="title">{{ $t('menu.refLinks') }}</div>
      <div class="query">
        <div class="select flex-column flex-middle">
          <p>{{ $t('ibAccounts.ibAccount') }}</p>
          <AccountNumber></AccountNumber>
        </div>
        <div class="select flex-column flex-middle" v-if="regulator !== 'FCA'">
          <p>{{ $t('referralLinks.selectCountryOrLang') }}</p>
          <el-select
            class="line_start"
            v-model="language"
            :filterable="true"
            @change="languageSelectChange"
            data-testid="language"
          >
            <el-option-group
              v-for="group in groupLanguageList"
              :key="group.label"
              :label="group.label"
              :value="group.value"
              :data-testid="group.label"
            >
              <el-option
                v-for="item in group.options"
                :key="item.label"
                :label="item.label"
                :value="item.value"
                :data-testid="item.label"
              ></el-option>
            </el-option-group>
          </el-select>
        </div>
        <!-- <div class="short_link_but d-inline-block" v-if="accountID">
          <el-switch
            v-model="switchValue"
            active-color="#6bdace"
            inactive-color="#1f4752"
            class="mb-1"
            @change="handleSwitchChange"
          >
          </el-switch>
          <span class="ps-2">{{ $t('referralLinks.hintShortLink') }}</span>
          <el-popover placement="bottom-start" popper-class="teal_popper" width="300" trigger="hover">
            <h5 class="mb-2">{{ $t('referralLinks.hintShortLinkTitle') }}</h5>
            <p>{{ $t('referralLinks.hintShortLinkMessage') }}</p>
            <img slot="reference" src="@/assets/uploads/referralLinks_icon.png" class="ms-1 mb-1" />
          </el-popover>
        </div> -->
      </div>
      <div class="referralLinks_content clearfix">
        <div class="item_header">
          {{ `${$t('menu.refLinks')}-${accountID}-${language}` }}
        </div>
        <ul class="link_container bottom-rouned" v-if="accountID">
          <li v-for="link in linkList" v-bind:key="link.name" class="link rounded flex flex-between">
            <div>
              <div v-if="link.name === $t('referralLinks.promotion')">
                <span>{{ link.name }}</span>
                <el-popover placement="bottom-start" popper-class="teal_popper" width="300" trigger="hover">
                  <h5 class="mb-2">{{ $t('referralLinks.hintTitle') }}</h5>
                  <p>{{ $t('referralLinks.hintMessage') }}</p>
                  <img slot="reference" src="@/assets/uploads/referralLinks_icon.png" class="ms-1" />
                </el-popover>
              </div>
              <span v-else>{{ link.name }}</span>
              <p>{{ link.url }}</p>
            </div>
            <button class="cursor-pointer" @click="onCopy(link.url)">{{ $t('referralLinks.copyLink') }}</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import AccountNumber from '@/components/form/AccountNumber';
import { apiGetShortLink, apiGetInviteCodeByIbCount, apiCustomInviteCode } from '@/resource';
import { setCookies, getCookies } from '@/util/cookies';
import { Base64 } from 'js-base64';
import { startLoading, endLoading } from '@/util/loading';
export default {
  name: 'ReferralLinks',
  components: { AccountNumber },
  data() {
    const validateNewCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(''));
      } else if (!/^[A-Za-z0-9@#$￥\-×÷=.,?!*^()~·€￡Ұ₴$₰￠₤￥₳₲₪₵]+$/.test(value)) {
        callback(new Error(this.$t('referralLinks.inviteCodeFormatError')));
      } else if (value.length < 6 || value.length > 32) {
        callback(new Error(this.$t('referralLinks.inviteCodeFormatError')));
      } else callback();
    };
    return {
      ruleForm: {
        newCode: ''
      },
      inviteCodeRules: {
        newCode: [
          {
            required: true,
            validator: validateNewCode,
            trigger: 'blur'
          }
        ]
      },
      referralCode: '',
      dialogVisible: false,
      switchValue: false,
      cookieName: 'switchState',
      userName: 'crmvau',
      domain: 'https://vigco.co/',
      language: '',
      languageList: {
        English: { language: this.$t('common.lang.english'), value: 'en' },
        Thai: { language: this.$t('common.lang.thai'), value: 'th' },
        Korean: { language: this.$t('Korean'), value: 'ko' },
        Chinese: { language: this.$t('common.lang.chinese'), value: 'zh_cn' },
        Malay: { language: this.$t('common.lang.malay'), value: 'ms' },
        Indonesian: { language: this.$t('common.lang.indo'), value: 'id' },
        Spanish: { language: this.$t('common.lang.spanish'), value: 'es' },
        Vietnamese: { language: this.$t('common.lang.viet'), value: 'vi' },
        Arabic: { language: this.$t('common.lang.arabic'), value: 'ar' },
        Mongolia: { language: this.$t('common.lang.mongolia'), value: 'mn' },
        Portuguese: { language: this.$t('common.lang.portuguese'), value: 'pt' },
        French: { language: this.$t('common.lang.french'), value: 'fr' },
        Japanese: { language: this.$t('common.lang.japanese'), value: 'jp' }
        // German: { language: this.$t('common.lang.german'), value: 'de-DE' },

        // India: { language: this.$t('India'), value: 'en-IN' },
        // Kazakhstan: { language: this.$t('Kazakhstan'), value: 'kk-KZ' },
        // Russian: { language: this.$t('Russian'), value: 'ru-RU' }
      },
      groupLanguageList: [
        {
          label: this.$t('referralLinks.byCountry'),
          options: [
            {
              label: this.$t('referralLinks.china'),
              value: 'china'
            },
            {
              label: this.$t('referralLinks.vietnam'),
              value: 'vietnam'
            },
            {
              label: this.$t('referralLinks.indonesia'),
              value: 'indonesia'
            }
          ]
        },
        {
          label: this.$t('referralLinks.byLanguage'),
          options: [
            {
              label: this.$t('common.lang.english'),
              value: 'en'
            },
            {
              label: this.$t('common.lang.thai'),
              value: 'th'
            },
            {
              label: this.$t('Korean'),
              value: 'ko'
            },
            {
              label: this.$t('common.lang.chinese'),
              value: 'zh_cn'
            },
            {
              label: this.$t('common.lang.malay'),
              value: 'ms'
            },
            {
              label: this.$t('common.lang.indo'),
              value: 'id'
            },
            {
              label: this.$t('common.lang.spanish'),
              value: 'es'
            },
            {
              label: this.$t('common.lang.viet'),
              value: 'vi'
            },
            {
              label: this.$t('common.lang.arabic'),
              value: 'ar'
            },
            {
              label: this.$t('common.lang.mongolia'),
              value: 'mn'
            },
            {
              label: this.$t('common.lang.portuguese'),
              value: 'pt'
            },
            {
              label: this.$t('common.lang.french'),
              value: 'fr'
            },
            {
              label: this.$t('common.lang.japanese'),
              value: 'jp'
            }
          ]
        }
      ],
      linkList: {
        liveAccountLink: { name: this.$t('referralLinks.liveAcc'), url: '' },
        demoAccountLink: { name: this.$t('referralLinks.demoAcc'), url: '' },
        homePageLink: { name: this.$t('referralLinks.homePage'), url: '' }
        // downloadAppLink: { name: this.$t('referralLinks.downloadPage'), url: '' }
      },
      idPrefix: '/?affid=',
      promotion: '/promotions/50percent-deposit-bonus',
      promotionLink: { name: this.$t('referralLinks.promotion'), url: '' },
      urlArr: []
    };
  },
  watch: {
    accountID() {
      console.log(this.accountID);
      if (this.accountID) {
        this.setLinkList();
        this.getInviteCode(this.accountID);
      }
    }
  },
  methods: {
    onCopy(item) {
      this.$copyText(item)
        .then(result => {
          this.$message(this.$t('referralLinks.alreadyCPY'));
          console.log('Copied :' + item);
        })
        .catch(err => {
          this.$message('Failed to copy' + err);
          console.log(err);
        });
    },
    getData(arr) {
      return arr.map(value => {
        if (typeof value === 'string') {
          return { url: value + '', name: this.userName, domain: this.domain };
        } else {
          return false;
        }
      });
    },
    handleSwitchChange() {
      startLoading();
      this.setLinkList();
      this.getPromotionLink(this.$store.state.common.countryCode);
      setCookies(this.cookieName, this.switchValue, 7);
    },
    languageSelectChange(item) {
      if (this.accountID != null) this.setLinkList();
    },
    getBaseDomain(fullUrl) {
      const parts = fullUrl.split('.');

      if (parts.length >= 2) {
        return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
      }

      return null;
    },
    getHostname() {
      return location.protocol + '//' + this.getBaseDomain(location.hostname);
    },
    getUrl() {
      let URL = `https://${this.GLOBAL_DOMAIN_WEBSITE}`;
      let reg = this.regulator.toLowerCase();
      let lang = this.language ? `/${this.language.toLowerCase()}` : '';
      if (reg === 'fca') URL = 'https://www.infinox.co.uk';
      let fcaHurl = `https://www.infinoxpartners.com${lang}/partner-application${this.idPrefix}${this.baseAccountId()}`;
      let urlst = URL;

      if (this.language === 'china') {
        this.urlArr = [
          `https://www.ixglobaltrades.hk/global/zh_cn/registration/live-account${this.idPrefix}${this.baseAccountId()}`,
          `https://www.ixglobaltrades.hk/global/zh_cn/registration/demo${this.idPrefix}${this.baseAccountId()}`,
          `https://www.ixglobaltrades.hk/global/zh_cn/${this.idPrefix}${this.baseAccountId()}`
        ];
      } else if (this.language === 'vietnam') {
        this.urlArr = [
          `https://www.dailyix.com/global/vi/registration/live-account${this.idPrefix}${this.baseAccountId()}`,
          `https://www.dailyix.com/global/vi/registration/demo${this.idPrefix}${this.baseAccountId()}`,
          `https://www.dailyix.com/global/vi${this.idPrefix}${this.baseAccountId()}`
        ];
      } else if (this.language === 'indonesia') {
        this.urlArr = [
          `https://www.ixdailytrader.com/global/id/registration/live-account${this.idPrefix}${this.baseAccountId()}`,
          `https://www.ixdailytrader.com/global/id/registration/demo${this.idPrefix}${this.baseAccountId()}`,
          `https://www.ixdailytrader.com/global/id${this.idPrefix}${this.baseAccountId()}`
        ];
      } else if (reg == 'fsc') {
        this.urlArr = [
          `${urlst}/global${lang}/registration/live-account${this.idPrefix}${this.baseAccountId()}`,
          `${urlst}/global${lang}/registration/demo${this.idPrefix}${this.baseAccountId()}`,
          this.language != 'zh_cn' ? fcaHurl : `${urlst}/global${lang}${this.idPrefix}${this.baseAccountId()}`
        ];
      } else {
        this.urlArr = [
          `${urlst}/${reg}${lang}/registration/live-account${this.idPrefix}${this.baseAccountId()}`,
          `${urlst}/${reg}${lang}/registration/demo${this.idPrefix}${this.baseAccountId()}`,
          reg == 'fsc' && this.language != 'zh_cn'
            ? fcaHurl
            : `${urlst}/${reg}${lang}${this.idPrefix}${this.baseAccountId()}`
        ];
      }
    },
    setLinkList() {
      this.getUrl();
      if (!this.switchValue) {
        this.linkList.liveAccountLink.url = this.urlArr[0];
        this.linkList.demoAccountLink.url = this.urlArr[1];
        this.linkList.homePageLink.url = this.urlArr[2];
        // this.linkList.downloadAppLink.url = this.urlArr[3];
        endLoading();
      } else {
        apiGetShortLink({
          PostShortUrlInputs: this.getData(this.urlArr)
        }).then(res => {
          this.linkList.liveAccountLink.url = res.data.result[0].fullShortUrl;
          this.linkList.demoAccountLink.url = res.data.result[1].fullShortUrl;
          this.linkList.homePageLink.url = res.data.result[2].fullShortUrl;
          this.linkList.downloadAppLink.url = res.data.result[3].fullShortUrl;
          endLoading();
        });
      }
    },
    setPromotionLink(languageLink) {
      let URL = 'www.infinox.com';
      if (this.regulator === 'FCA') URL = 'www.infinox.co.uk';
      if (languageLink === '/ms-my') URL = 'www.infinox.com';

      let promotionUrl = [`https://${URL}${languageLink}${this.promotion}${this.idPrefix}${this.baseAccountId()}`];
      let data = {
        PostShortUrlInputs: this.getData(promotionUrl)
      };
      if (!this.switchValue) {
        this.promotionLink.url = promotionUrl[0];
      } else {
        apiGetShortLink(data).then(res => {
          this.promotionLink.url = res.data.result[0].fullShortUrl;
        });
      }
      this.linkList.promotionLink = this.promotionLink;
    },
    setDefaultLanuage(countryCode) {
      switch (countryCode) {
        case '1':
          this.language = 'china';
          break;
        case '6991':
          this.language = 'vietnam';
          break;
        default:
          this.language = 'en';
      }
    },
    getPromotionLink(countryCode) {
      switch (countryCode) {
        case '1':
          this.setPromotionLink('/zh-hans');
          break;
      }
    },
    baseAccountId() {
      return Base64.encode(this.accountID);
    },
    //custom button
    openCustomDialog() {
      this.dialogVisible = true;
    },

    //submit new referral code
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          this.$message(this.$t('referralLinks.inviteCodeFormatError'));
        } else {
          apiCustomInviteCode({
            login: this.accountID,
            customInviteCode: this.ruleForm.newCode
          }).then(res => {
            if (res.data.code === 0) {
              this.referralCode = this.ruleForm.newCode;
              this.ruleForm.newCode = '';
              this.dialogVisible = false;
            } else if (res.data.code === -1) {
              this.$message(this.$t('referralLinks.inviteCodeAlreadyExist'));
            }
          });
        }
      });
    },

    copySuccess() {
      this.$message('复制成功');
    },
    getInviteCode(accountID) {
      apiGetInviteCodeByIbCount(accountID).then(res => {
        this.referralCode = res.data.data.randomInvitationCode;
      });
    }
  },
  mounted() {
    this.getInviteCode(this.accountID);
    if (getCookies(this.cookieName)) {
      this.switchValue = JSON.parse(getCookies(this.cookieName));
    }
    this.setDefaultLanuage(this.$store.state.common.countryCode);
    // this.getPromotionLink(this.$store.state.common.countryCode);
    this.setLinkList();
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    lang() {
      return this.$store.state.common.lang;
    },
    regulator() {
      return this.$store.state.common.regulator.toUpperCase();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/referralLinks.scss';
</style>
